<template>
  <div class="rb-view">
    <div>
      <div>
        <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单日期:" prop="time_range">
                <el-radio-group v-model="search_option.time_range" @change="handleSearchTap">
                  <el-radio-button label="all">全部</el-radio-button>
                  <el-radio-button label="today">今日</el-radio-button>
                  <el-radio-button label="yesterday">昨天</el-radio-button>
                  <el-radio-button label="7day">7天内</el-radio-button>
                  <el-radio-button label="30day">30天内</el-radio-button>
                  <el-radio-button label="this_month">今月</el-radio-button>
                  <el-radio-button label="this_year">今年</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单日期:" prop="time_range">
                <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                <el-date-picker @change="handleSearchTap"
                                v-model="search_option.time_range"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="ID:" prop="id">
                <el-input clearable size="mini" v-model="search_option.id"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="课程名称:" prop="title">
                <el-input clearable v-model="search_option.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询
              </el-button>
              <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-button type="primary" icon="el-icon-tickets" size="mini" @click="onCreateTap">新增</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="margin-top: 16px;">
        <el-table :data="page_list" size="mini" border style="width:100%;">
          <el-table-column label="ID" prop="id" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column label="课程ID" prop="fitness_id" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column label="名称" prop="title"></el-table-column>
          <el-table-column label="人数上限" prop="people_limit_up" width="80" align="right"></el-table-column>
          <el-table-column label="参与人数" prop="people_total" width="80" align="right"></el-table-column>
          <el-table-column label="场次状态" prop="status" width="80" align="right">
            <template #default="scope">
              <span :style="{color:(params_map['schedule_status']&&params_map['schedule_status'][scope.row.status])?params_map['schedule_status'][scope.row.status].color:''}">{{ (params_map['schedule_status'] && params_map['schedule_status'][scope.row.status]) ? params_map['schedule_status'][scope.row.status].value : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" prop="begin_at" width="136" :formatter="elTimeStampFormat"></el-table-column>
          <el-table-column label="结束时间" prop="end_at" width="136" :formatter="elTimeStampFormat"></el-table-column>
          <el-table-column label="总时长-秒" prop="duration_seconds" align="right" width="136"></el-table-column>
          <el-table-column label="原价" prop="market_price" align="right" width="80">
            <template #default="scope">
              <span style="text-decoration:line-through;color: #8c939d;">￥{{scope.row.market_price}}</span>
            </template>
          </el-table-column>
          <el-table-column label="早鸟价" prop="price" align="right" width="80">
            <template #default="scope">
              <span style="color: #F56C6C;font-weight: bold">￥{{scope.row.price}}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否隐藏" prop="price" align="right" width="80">
            <template #default="scope">
              <span v-if="scope.row.is_private" style="color: #67C23A;font-weight: bold">隐藏</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template #default="scope">
              <el-button type="text" @click="onViewTap(scope.row)" size="mini">查看</el-button>
              <el-button v-if="scope.row.status != 4" type="text" @click="onEditTap(scope.row)" size="mini">编辑
              </el-button>
              <el-button v-if="scope.row.status != 4" type="text" @click="onRemoveTap(scope.row)" size="mini"
                         style="color:#F56C6C;">删除
              </el-button>
              <el-button v-if="scope.row.status != 4" type="text" @click="onShowQrcode(scope.row)" size="mini"
                         style="color:#000000;">二维码
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page_option.page"
              :page-sizes="[5 ,10, 20, 30, 50,100,200]"
              :page-size="page_option.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page_option.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogFormVisible" :title="dialogFormTitle" @close="onTellDialogClose" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
      <schedule-detail :action="dialogFormAction" :row-id="dialogFormRowId" @onTellDialogClose="onTellDialogClose"></schedule-detail>
    </el-dialog>
    <el-dialog v-model="show_qrcode" @close="onTellDialogClose" width="400px">
      <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
        <div style="width: 100%;font-size: 14px;font-weight: bold">课程名称：{{show_qr_title}}</div>
        <vue-qr :text="qr_url" :size="200"></vue-qr>
        <div>{{qr_url}}</div>
      </div>
    </el-dialog>
    </div>
</template>

<script>
    import VueQr from 'vue-qr/src/packages/vue-qr.vue'
    import ScheduleDetail from './schedule-detail'
    import {getList,remove} from "@/api/vstar/schedule";
    import {getParams} from "@/api/vstar/param";
    import {formatTimestamp} from "@/utils/time";
    const param_list = ['schedule_status']
    export default {
        name: "schedule-list",
        components:{ScheduleDetail,VueQr},
        data(){
            return{
                dialogFormTitle:'',
                dialogFormVisible:false,
                dialogFormRowId:0,
                dialogFormAction:'',//create view edit

                loading:false,
                search_form_name:'schedule_search',
                page_option:{page:1,size:10,total:0},
                search_option:{playground_type:'all',code:'all'},
                page_list:[],
                params:{playground_type:[],},
                params_map_pure:{},
                params_map:{recharge_type:{},},

                show_qrcode:false,
                qr_url:'',
                show_qr_title:''

            }
        },
        created(){
            this.initPage()
        },
        methods: {
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            onViewTap(row) {
                console.log('onViewTap:', row)
                this.dialogFormRowId = row.id
                this.dialogFormAction = 'view'
                this.dialogFormVisible = true
            },
            onEditTap(row) {
                console.log('onEditTap:', row)
                this.dialogFormAction = 'edit'
                this.dialogFormRowId = row.id
                this.dialogFormVisible = true
            },
            onCreateTap() {
                console.log('onCreateTap')
                this.dialogFormRowId = 0;
                this.dialogFormAction = 'add'
                this.dialogFormVisible = true
            },
            onRemoveTap(row) {
                this.$messageBox.confirm('确定删除当前数据?', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                }).then(() => {
                    remove(row.id).finally(() => {
                        this.reloadPage()
                    })
                })
            },
            onTellDialogClose() {
                this.dialogFormVisible = false;
                this.dialogFormRowId = 0;
                this.reloadPage()
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            onShowQrcode(row){
              console.log(row)
              this.show_qrcode = true
              this.qr_url = row.qr_code_url
              this.show_qr_title = row.title
            }
        }
    }
</script>

<style scoped>

</style>
