<template>
  <div>
    <el-form :model="form_data" :rules="formRules" :ref="form_name" :disabled="formDisabled" size="mini"
             label-position="right" label-width="100px">
      <el-form-item label="课程模版:" prop="playground_id">
        <el-select v-model="form_data.fitness_id" filterable placeholder="请选择课程模版" style="width: 240px" @change="onSelectFitnessChange">
          <el-option v-for="(p) in params.fitness" :key="p.key" :label="p.value" :value="p.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排课名称:" prop="title" required>
        <el-input v-model="form_data.title" style="width: 240px"></el-input>
      </el-form-item>
      <el-form-item label="限定人数:" prop="people_limit_up">
        <el-input-number v-model="form_data.people_limit_up" :step="1" :min="0" :precision="0"></el-input-number>
        <span v-if="select_fitness && select_fitness.id" style="padding:0 16px;">上限 <span
            style="font-weight:bold;color:#409EFF;">{{ select_fitness.people_limit_up }}</span> 人</span>
        <span style="margin-left:12px;cursor: pointer;color:#409EFF;font-weight:bold;text-decoration-line: underline" @click="onSetMaxPeopleTap">设置最大</span>
      </el-form-item>
      <el-form-item label="开始时间:" required>
        <el-date-picker v-model="form_data.begin_at" type="datetime" placeholder="选择日期时间" format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="原价:" required>
        <el-input-number v-model="form_data.market_price"></el-input-number>
      </el-form-item>
      <el-form-item label="早鸟价:" required>
        <el-input-number v-model="form_data.price"></el-input-number>
      </el-form-item>
      <el-form-item label="时长:" required>
        <el-input-number v-model="form_data.duration_seconds"></el-input-number>
      </el-form-item>
      <el-form-item label="是否隐藏:" prop="is_private">
        <el-switch v-model="form_data.is_private"></el-switch>
      </el-form-item>
      <el-form-item label="教练名称:">
        <el-input v-model="form_data.coach_name" style="width: 240px"></el-input>
      </el-form-item>
      <el-form-item label="头像显示:">
        <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :headers="uploadHeader"
            :show-file-list="false"
            :limit="1"
            :on-preview="handleCoverPreview"
            :on-remove="handleCoverRemove"
            :on-success="onCoachCoverUploadSuccess"
            :before-upload="beforeCoverFileUpload">
          <img style="max-width: 178px;max-height: 178px" v-if="form_data.coach_cover" :src="imgHost+form_data.coach_cover"
               class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="封面展示:" required>
        <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :headers="uploadHeader"
            :show-file-list="false"
            :limit="1"
            :on-preview="handleCoverPreview"
            :on-remove="handleCoverRemove"
            :on-success="onFitnessCoverUploadSuccess"
            :before-upload="beforeCoverFileUpload">
          <img style="max-width: 178px;max-height: 178px" v-if="form_data.fitness_cover" :src="imgHost+form_data.fitness_cover"
               class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="text-align:right;">
      <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
      <el-button @click="onCloseDialog" size="mini">关闭</el-button>
    </div>

  </div>
</template>

<script>
import {getParams} from "@/api/vstar/param";
import {getDetail, submit} from "@/api/vstar/schedule";
import {getDetail as getFitnessDetail} from "@/api/vstar/fitness";
import {ElMessage} from "element-plus";
import { getToken } from '@/utils/auth'

const param_list = ['fitness']
export default {
  name: "schedule-detail",
  props: {
    action: {
      value: String,
      default: 'add'
    },
    rowId: {
      value: String,
    }
  },
  data() {
    return {
      form_name: 'schedule_detail',
      form_data: {time_range: []},
      formRules: {},
      params: {},
      params_map: {},
      params_map_reverse: {},

      select_fitness: null,

      imgHost: process.env.VUE_APP_BASE_API,
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/sys/file/upload',
      uploadHeader: {},
    }
  },
  mounted() {
    this.uploadHeader['Authorization'] = 'Bearer ' + getToken();
  },
  computed: {
    formDisabled() {
      return this.action === 'view' || !this.action;
    }
  },

  watch: {},
  created() {
    this.initPage()
  },

  methods: {
    initParams() {
      return new Promise((resolve, reject) => {
        getParams(param_list.join(','), 'zh-CN').then(resp => {
          this.params = resp.data;

          let tmp_params_map = {}
          let tmp_params_map_reverse = {}

          //这里再这里一下每组参数做成kv方便显示
          let p_keys = Object.keys(this.params)
          p_keys.forEach(pl => {
            console.log('pl:', pl)
            let tmp_map = {}
            let tmp_map_reverse = {}
            this.params[pl].forEach(pm => {
              // tmp_map[pm.key]=pm.value
              tmp_map[pm.key] = {value: pm.value, color: pm.color}
              //再调转处理一下 导入Excel参数解析用
              tmp_map_reverse[pm.value] = pm.key
            })
            tmp_params_map[pl] = tmp_map
            tmp_params_map_reverse[pl] = tmp_map_reverse
          })

          this.params_map = tmp_params_map
          this.params_map_reverse = tmp_params_map_reverse
          console.log('params_map:', this.params_map)
          console.log('params_map_reverse:', this.params_map_reverse)
          resolve(resp)
        }).catch((err) => {
          if (err) {
            console.error(err)
          }
          reject(err)
        })
      });
    },
    initPage() {
      this.initParams().then(() => {
        if (this.rowId > 0) {
          getDetail(this.rowId).then(resp => {
            console.log(resp.data)
            this.form_data = resp.data
            this.prepareSelectFitness()
          })
        }
      })
    },
    onCloseDialog() {
      this.$emit("onTellDialogClose")
    },
    submitForm(formName) {
      if (this.form_data.time_range && this.form_data.time_range.length == 2) {
        this.form_data.begin_at = new Date(this.form_data.time_range[0]).getTime();
        this.form_data.end_at = new Date(this.form_data.time_range[1]).getTime();
      }
      this.form_data.begin_at = new Date(this.form_data.begin_at).getTime()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          submit(this.form_data).then(() => {
            this.$emit('onTellDialogClose')
          })
        }
      })
    },
    onSetMaxPeopleTap() {
      if (this.select_fitness && this.select_fitness.id) {
        this.form_data.people_limit_up = this.select_fitness.people_limit_up;
      }
    },
    onSelectFitnessChange(e) {
      let that = this
      console.log('onSelectPlaygroundChange:', e)
      getFitnessDetail(e).then(resp => {
        // let pd = resp.data
        that.select_fitness = resp.data
        console.log('this.select_fitness',that.select_fitness)
        that.form_data = resp.data
        that.form_data.fitness_id = e
        that.form_data.id = ''
        this.form_data.fitness_cover = resp.data.cover
      })
    },
    prepareSelectFitness() {
      if (this.form_data.fitness_id) {
        getFitnessDetail(this.form_data.fitness_id).then(resp => {
          // let pd = resp.data
          this.select_fitness = resp.data
          console.log('this.select_fitness',this.select_fitness)
        })
      }
    },
    onCopyTitleTap() {
      if (this.select_fitness && this.select_fitness.id) {
        this.form_data.title = `${this.select_fitness.code}||${this.select_fitness.title}`
      }
    },


    // 图片上传
    beforeCoverFileUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        ElMessage({message: '上传图片只能是 JPG 或 PNG 格式!', type: 'warning'})
      }
      if (!isLt2M) {
        ElMessage({message: '上传头像图片大小不能超过 2MB!', type: 'warning'})
      }
      return (isJPG || isPNG) && isLt2M
    },

    handleCoverPreview(file) {
      console.log(file)
    },
    handleCoverRemove(file, fileList) {
      console.log(file, fileList)
    },
    onFitnessCoverUploadSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.form_data.fitness_cover = response.data.url;
        console.log(this.form_data.fitness_cover)
        console.log(this.imgHost + this.form_data.fitness_cover)
        this.$forceUpdate()  //强制i更新
      }
    },
    onCoachCoverUploadSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        this.form_data.coach_cover = response.data.url;
        console.log(this.form_data.coach_cover)
        console.log(this.imgHost + this.form_data.coach_cover)
        this.$forceUpdate()  //强制i更新
      }
    }
  }
}
</script>

<style scoped>
/deep/.avatar-uploader .el-upload {border: 1px dashed #d9d9d9;border-radius: 6px;cursor: pointer;position: relative;overflow: hidden;line-height: 0}
/deep/.avatar-uploader .el-upload:hover {border-color: #409EFF;}
.avatar-uploader-icon {font-size: 28px;color: #8c939d; width: 128px;height: 60px;line-height: 60px;text-align: center;}
</style>