import request from '@/utils/request'

//批量获取参数列表
export const getParams = (param_names_str)=>{
    return request({
        url:'/api/vstar/param/get',
        method:'get',
        params:{names:param_names_str,}
    })
}
